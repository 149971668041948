header {
    margin-top: 6rem;
    display: flex;
    place-items: center;
    background: #0a192f;
    transition: var(--transition);
    height: auto;
}

#change {
    color: #f9b294;
}

#maniac {
    background-color: #325d7f;
}

.container__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    min-height: 100vh;
    margin-inline: 13% 13%;
}

.greeting {
    margin: 0px 0px 30px 4px;
    font-weight: 400;
    font-size: clamp(14px, 5vw, 16px);
    color: #c06c86  ;
}

.heading {
    margin-bottom: 10px;
    font-size: clamp(30px, 7vw, 65px);
}

.info {
    /* margin: 20px 0px 0px; */
    max-width: 540px;
}

.header__profile {
   border-radius: 9rem 9rem 2rem 2rem;
   overflow: hidden;
   margin-bottom: 1.5rem;
}

.container__header p {
    width: 35rem;
    margin: 0.6rem 0 2rem;
    
}

.header__cta {
    display: flex;
    align-items: center;
    gap: 1.2rem;
}

.header__socials {
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.header__socials a {
    width: 2rem;
    height: 2rem;
    background: #325d7f;
    display: grid;
    place-items: center;
    border-radius: var(--border-radius-3);

}

.btn {
    box-shadow: 0 10px 30px -15px blue;
}

.header__socials a:hover {
    border-color: var(--color-light);
    color: white;
    transform: translateX(-0.5rem);
}

@media screen and (max-width: 1024px) {
    header {
        margin-top: 0;
        height: 100vh;
    }
}

@media screen and (max-width: 600px) {
    .header__profile {
        margin-top:7rem;
        height: 17rem;
        max-width: 60%;
        max-height: 35vh;
    }

    .container__header p{
        width: 100%;

    }

    .header__cta {
        gap: 1rem;
    }

    .header__socials {
        display: none;
    }
}