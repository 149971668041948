.inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
}

.about__left {
    margin-right: 40px;
   
}

li {
    color: #c06c86;
}
.header_div {
    align-items: center;
    margin: 10px 0px 40px;
    width: 100%;
    font-size: clamp(26px,5vw,32px);
}

.about__portrait {
    box-shadow: 0 10px 30px -15px blue;
}

.about_header::after { 
    content: "";
    display: inline-block;
    position: relative;
    top: -10px;
    width: 300px;
    height: 1px;
    margin-left: 20px;
    background-color: #c06c86;       
}

.tint-magenta{
    
    border-radius: 5px;
    filter: hue-rotate(-30deg) sepia(25%) contrast(115%) saturate(300%) hue-rotate(05deg);
  }

  .tint-magenta:hover {
    filter: hue-rotate(-270deg) sepia(25%) contrast(115%) saturate(300%) hue-rotate(270deg);
  }

.skills__container {
    display: flex;
    flex-direction:row;  
    justify-content: space-between;
    margin-top: 40px;
}

.frontend_skills {
    height: 100px;
    width: 30%;
    text-align: center;
}

.backend_skills {
    height: 100px;
    width: 30%;
    text-align: center;

}

.languages {
    height: 100px;
    width: 30%;
    text-align: center;

}

@media screen and (min-width: 0px) and (max-width: 415px) {
    .about_header::after {  
        width: 100px;       
    }
}

@media screen and (min-width: 0px) and (max-width: 358px) {
    .about_header::after {  
        width: 80px;       
    }
}

@media screen and (min-width: 0px) and (max-width: 329px) {
    .about_header::after {  
        width: 60px;       
    }
}



@media screen and (min-width: 0px) and (max-width: 750px) {
    .about__container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .inner {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .about__right {
        margin-top: 4em;
    }

    .skills__container {
        flex-flow: row wrap;
    }
    

    .skills__container > div {     
        flex-basis: calc(50% - 10px);       
    }

    .two {
        padding-top: 70px;
        text-align: center;
    }
}

/* .about__portrait > img {
    width: 280px;
} */