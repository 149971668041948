nav {
    width: 100vw;
    height: 4rem;
    display: grid;
    place-items: center;
    /* background: var(--color-primary); */
    /* position: fixed; */
    top: 0;
    left: 0;
    z-index: 9;
    /* box-shadow: 0 1rem 1.5rem hsla(var(--primary-hue), */
     /* 68%, 42%, 20%); */
    /* transition: var(--transition); */
    background-color: #45637b(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
}



.nav__container {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav__logo {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    overflow: hidden;
    /* position: absolute; */
    /* left: 3rem; */
    /* display: flex; */
    /* flex-direction: column; */
}

.nav__logo img {
    width: 3rem;
}

.nav__menu {
    display: flex;
    gap: 3.5rem;
}

#theme__icon {
    background: transparent;
    color: white;
    font-size: 2rem;
    cursor: pointer;
}

.resume__button {
    /* color: #f9b294; */
    background-color: #45637b;
    border: 2px solid transparent;
    border-radius: 10px;
    padding: 0.75rem 0.5rem;
    font-family: var(--font-mono);
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    /* transition: var(--transition); */
    /* transition: background-color 2s ease-out; */
    transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);;
        
}

.resume__button:hover {
    background-color: transparent;
    
}

.doner {
    /* position: sticky; */
    /* top: 0px; */
    /* background-color: #BFDBFE; */
    /* height: 6rem; */
    transition-property: all; 
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
  }
  
  .doner {
    position: sticky;
    transition-property: all; 
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
  }
  
  .doner.hide {
    top: -4rem;
  }

  .menu.visible > li {
    font-size: 20px;
    text-color: #c06c86
  }



@media screen and (max-width: 750px) {
    .nav__menu {
        display: none;
    }
}

@media screen and (min-width: 0px) and (max-width: 750px) {
    .burger-menu {
        height: 1.5em;
        width: 3em;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        cursor: pointer;

    }

    .burger-bar {
        width: 2em;
        height: 0.2em;
        background-color: #f9b294;
        border-radius: 0.5em;
    }

    .menu {
        width: 70%;
        height: 100vh;
        background-color: #45637b;
        position: fixed;
        z-index: 2;
        top: 0;
        right: 0px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        filter: none;
        box-shadow: 0 1rem 1.5rem hsla(var(--primary-hue),
     68%, 42%, 20%);
    }

    .menu.hidden {     
        right: -70%;
        transition: 1s;
    }

    .menu.visible {
        transition: 1s;
        right: 0;
        overflow: hidden;
        padding-top: 6%;
        padding-bottom: 6%;
    } 
    
    .menu > .burger-menu {
        position: absolute;
        top: 20px;
        right: 10px;
    }

    /* clicked */

    .burger-bar.clicked:nth-child(1){     
        transform: rotate(45deg) translate(-0.01em, .90em);
        transition: ease-out 0.5s;
    }

    .burger-bar.clicked:nth-child(2){     
        transform: scale(0);
        transition: ease-out 0.1s;
    }

    .burger-bar.clicked:nth-child(3){     
        transform: rotate(135deg) translate(-0.01em, 1em);
        transition: ease-out 0.5s;
    }

    /* unclicked */

    .burger-bar.unclicked {
        transform: rotate(0) translate(0);
        transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
    }

    body:has(.menu.visible):not(.menu) {
        /* filter:blur(4px); */
        overflow: hidden;
    }

    .blur {
        height: 100%;
        width: 100%;
        /* filter: blur(4px); */
        position: fixed;
        background-color: transparent;
        backdrop-filter: blur(2.7px);
        z-index: 1;
    }

}

@media screen and (min-width: 750px) {
    .burger-menu {
        display: none;
    }

    .menu {
        display: none;
    }
}