.projectCards {
  display: flex;
  flex-direction:row;
  justify-content:space-around;
  width: 100%;
  gap: 10px;
  flex-flow: row wrap;
}

.cards {
  box-shadow: 0 10px 30px -15px blue;
  border-radius: 4px;
  width: clamp(200px, 40%, 400px);
  height: 320px;
  background-color: #45637b;
  flex-basis: calc(50% - 10px);
  padding: 25px 25px 20px;
  position: relative;
}

.cards:hover {
  transform: translateY(-0.3rem);
  transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);
}

.projectCardsList {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  position: relative;
  gap: 15px;
}


.projects {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.projects .projectTitle {
  text-align: center;
  color: #3e497a;
  font-size: 60px;
  font-weight: bolder;
}

.projectsList {
  width: 70vw;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  
}


/* MENU ITEM STYLING */

.projectItem {
  border-radius: 0px;
  width: 80%;
  height: 300px;
  margin: 40px 40px 40px 0px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
}
.projectItem:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in;
  cursor: pointer;
}

.projectItem .bgImage {
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.projectItem h1 {
  font-size: 25px;
}
@media only screen and (min-width: 0px) and (max-width: 1024px) {
  .projectsList {
    align-items: center;
  }

  .projectItem {
    margin: 0;
  }
}

@media only screen and (max-width: 1300px) {
  .projectsList {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .projectsList {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .projectsList {
    width: 100%;
  }
  .projectItem {
    width: 300px;
    height: 300px;
  }
}

.header_div {
  align-items: center;
  margin: 10px 0px 40px;
  width: 100%;
  font-size: clamp(26px,5vw,32px);
}

.port_header::after { 
  content: "";
  display: inline-block;
  position: relative;
  top: -10px;
  width: 300px;
  height: 1px;
  margin-left: 20px;
  background-color: #c06c86;       
}

.project_image {
  border-radius: 5px;
}

@media screen and (min-width: 0px) and (max-width: 415px) {

  .port_header::after {
      width: 150px;
  }
}

@media screen and (min-width: 0px) and (max-width: 388px) {

  .port_header::after {
      width: 130px;
  }
}

@media only screen and (min-width: 750px) and (max-width: 1240px) {
  .cards {
    flex-basis: calc(50% - 10px)
  }
}

@media only screen and (min-width: 832px) and (max-width: 1080px) {
  .cards {
    flex-basis: calc(50% - 10px);
    height: 380px;
  }
}

@media only screen and (min-width: 398px) and (max-width: 593px) {
  .cards {
    flex-basis: calc(50% - 10px);
    height: 380px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 398px) {
  .cards {
    flex-basis: calc(50% - 10px);
    height: 410px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 831px) {
  .cards {
    flex-basis: calc(100% - 10px)
  }
}

@media only screen and (min-width: 0px) and (max-width: 750px) {
  .cards {
    flex-basis: calc(100% - 10px)
  }
}

.project-description {
  box-shadow: 0 10px 30px -15px var(--navy-shadow);
  position: relative;
  margin-top: 35px;
}

.project-tech-list {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 25px 0px 10px;
  padding: 0px;
  list-style: none;
}

.project-tech-list li {
  position: relative;
  padding-right: 11px;
}

.project-links {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  margin-top: 30px;
  justify-content: center;
  bottom: 0;
  position: relative;
}


.coco {
  display: flex;
  flex-direction: row;
  width: 85%;
  margin-bottom: 25px;
  position: absolute;
  bottom: 0;
  justify-content: space-between;
}



.overlay {
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #45637b;
  overflow: hidden;
  width: 100%;
  height:0;
  transition: all 0.5s ease-in-out;
}


.cards:hover .overlay {
  top: 0;
  height: 79%;
}

.overlay.slide {
  top: 0;
  height: 79%;
}

