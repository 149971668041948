.contact_header::after { 
    content: "";
    display: inline-block;
    position: relative;
    top: -10px;
    width: 300px;
    height: 1px;
    margin-left: 20px;
    background-color: #c06c86;       
  }

  .intouch {
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    margin-top: 90px;
    
  }


  @media screen and (min-width: 0px) and (max-width: 415px) {
    .contact_header::after {  
        width: 150px;       
    }
}

@media screen and (min-width: 0px) and (max-width: 388px) {

  .contact_header::after {
      width: 130px;
  }
}

.linko {
  margin-top: 30px;
}

.linko > a {
  transform: translateY(-0.5rem);
}

#con_link {
  margin-right: 30px;
}
